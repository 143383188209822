<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 21:49:38
-->
<template>
  <a-row
    :gutter="20"
    class="margin-bottom"
    type="flex"
    justify="end"
    align="middle"
  >
    <!-- <a-col>
 
    </a-col>

    <a-col>
      <a-input
          v-model:value="filterState.keyword"
          placeholder="輸入關鍵字搜索"
          allow-clear
      />
    </a-col> -->

    <!-- <a-col>
      <a-button type="primary">搜索</a-button>
    </a-col> -->
  </a-row>

  <a-table
    :dataSource="dataSourceRef"
    :columns="columns"
    :loading="tableLoading"
    :pagination="pagination"
    :scroll="{
      x: 0,
      y: 600,
      scrollToFirstRowOnChange: true,
    }"
    @change="tableChange"
  />
</template>

<script>
import useTable from "@/composition/useTable";
import useTableFilter from "@/composition/useTableFilter";

export default {
  setup() {
    const {
      dataSourceRef,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
      settingData,
    } = useTable({ get: "/background/userSettlement" });

    // 篩選數據
    const { filterState, filterFn } = useTableFilter(
      "/background/userSettlement",
      {
        keyword: "",
        active_type: "",
      }
    );

    const search = async () => {
      const res = await filterFn();
      settingData(res);
    };

    return {
      dataSourceRef,
      filterState,
      search,
      filterFn,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
      columns: [
        {
          title: "用户排名",
          dataIndex: "userRank",
          key: "userRank",
          align: "center",
        },
        {
          title: "用户ID",
          dataIndex: "userId",
          key: "userId",
          align: "center",
          width: 300,
        },
        {
          title: "结算年份",
          dataIndex: "settYear",
          key: "settYear",
          align: "center",
        },
        {
          title: "结算月份",
          dataIndex: "settMonth",
          key: "settMonth",
          align: "center",
        },
        {
          title: "是否领取",
          dataIndex: "isDraw",
          key: "isDraw",
          align: "center",
          customRender: (text) =>
            new Object({ children: text.isDraw == 1 ? "是" : "否" }),
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
